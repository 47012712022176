<template>
    <v-row no-gutters class="ma-2">
        <v-col cols="12" sm="6">
            <ProgressCircle v-model="value.rel_aprob" color="green" text-color="gray" title="% Realizadas" />
        </v-col>
        <v-col cols="12" sm="6">
            <ProgressCircle v-model="value.rel_inv" color="red" text-color="gray" title="% No Realizadas" />
        </v-col>
        <v-col cols="12">
            <v-divider />
            <v-data-table dense :headers="headers" :items="value.logs" :group-by="value.is_agrupado ? 'grupo' : null">
                <template v-slot:group.header="{ group, toggle, isOpen, headers }">
                    <td :colspan="headers.length" class="pa-0" @click="toggle">
                        <v-row no-gutters justify="center">
                            <v-col class="flex-grow-1 flex-shrink-0 fill-height pa-2">
                                <span :title="group">
                                    <v-icon left>group</v-icon>{{ group | see_more(140) }}
                                </span>
                            </v-col>
                            <v-col class="flex-grow-0 flex-shrink-1 pa-2">
                                <v-icon v-if="!isOpen">expand_more</v-icon>
                                <v-icon v-else>expand_less</v-icon>
                            </v-col>
                        </v-row>
                    </td>
                </template>
                <template v-slot:item.fecha_termino="{ item }">
                    <v-icon left v-if="item.is_completado" color="green">check_circle</v-icon>
                    <v-icon left v-else color="red">error_outline</v-icon>
                    <span v-if="item.is_completado">{{ item.fecha_termino | moment('L - HH:mm') + ' Hrs'}}</span>
                    <span v-else>No realizada</span>
                </template>
            </v-data-table>
        </v-col>
    </v-row>
</template>

<script>
import ProgressCircle from '@/dash/components/ProgressCircle'
import ProgressCounter from '@/dash/components/ProgressCounter'

export default {
    props: ['value'],
    components: {
        ProgressCircle,
        ProgressCounter
    },
    computed: {
        pag_length() {
            if(!this.value) return 1;
            return Math.max(1, Math.round(this.value.length / this.pag.items));
        }
    },
    data: () => ({
        headers: [
            { value: "evaluacion.nombre", text: "Evaluación", width: "25%" },
            { value: "curso", text: "Curso", width: "25%"  },
            { value: "fecha_termino", text: "Estado Evaluación", width: "25%"  }
        ]
    })
}
</script>