<template>
    <div>
        <BuscadorReporteCursosInternosRendimiento :activeSubTabCursosInternos="activeSubTabCursosInternos" class="custom-background" @datos-recibidos="actualizarItems" @clear-table="clearTable" :isData="isData"/>
        <v-divider />
        <div v-if="data.tipo_reporte === 'rendimiento_persona'">
            <v-row>
                <v-col  cols="12">
                    <v-alert class="ma-0" type="success">Datos del participante</v-alert>
                    <v-data-table :headers="headers_persona" :items="persona" :mobile-breakpoint="0"  hide-default-footer>
                        <template #item.key="{ item }">
                            <span>{{ item.key }}</span>
                        </template>
                        <template #item.value="{ item }">
                            <span>{{ item.value }}</span>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
            <v-row>
                <v-col  cols="12">
                    <v-alert class="ma-0" type="success">
                        Indicadores
                    </v-alert>
                    <v-data-table :headers="headers_indicadores" :items="indicadores" :mobile-breakpoint="0"  hide-default-footer>
                        <template #item.Parametro="{ item }">
                            <span>{{ item.key }}</span>
                        </template>
                        <template #item.Porcentaje="{ item }">
                            <span>{{ item.value }}</span>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
            <v-row>
                <v-col  cols="12">
                    <v-alert class="ma-0" type="success">
                        Estado de las evaluaciones
                    </v-alert>
                    <v-data-table :headers="headers_estado" :items="data.data.evaluaciones.logs" :mobile-breakpoint="0"  hide-default-footer>
                        <template #item.Evaluacion="{ item }">
                            <span>{{ item.evaluacion.nombre }}</span>
                        </template>
                        <template #item.Curso="{ item }">
                            <span>{{ item.curso }}</span>
                        </template>
                        <template #item.Desde="{ item }">
                            <span>{{ item.is_completado ? getFecha(item.fecha_inicio) : '-' }}</span><br>
                            <span>{{ item.is_completado ? getHora(item.fecha_inicio) : '-'}}</span>
                        </template>
                        <template #item.Hasta="{ item }">
                            <span>{{ item.is_completado ? getFecha(item.fecha_termino) : '-' }}</span><br>
                            <span>{{ item.is_completado ? getHora(item.fecha_termino) :'-'}}</span>
                        </template>
                        <template #item.Estado="{ item }">
                            <span :style="{ color:  item.is_completado ? 'black' : 'red' }">{{ item.is_completado ? 'completado':'pendiente' }}</span>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
            <v-row>
                <v-col  cols="12">
                    <v-alert class="ma-0" type="success">
                        Detalle por evaluaciones completadas
                    </v-alert>
                </v-col>
            </v-row>
            <v-data-table :headers="headers" :items="data.rendimiento_participante_table" :mobile-breakpoint="0" :items-per-page="10">
                <template #item.evaluaciones_participante="{ item }">
                    <span><strong>Evaluación:</strong> {{ item.nombre }}</span><br>
                    <span><strong>Curso:</strong> {{ item.nombre_curso }}</span><br>
                    <span><strong>Grupo:</strong> {{ item.nombre_grupo }}</span><br>
                    <span><strong>Cantidad de preguntas:</strong> {{ item.cantidad_preguntas }}</span><br>
                    <span><strong>Total desaciertos :</strong> {{ item.total_desaciertos }}</span><br>
                    <span><strong>Duración:</strong> {{ item.duracion }}</span>
                </template>
                <template #item.respuestas_participante="{ item }">
                    <v-data-table :headers="sub_headers" :items="item.respuestas" :mobile-breakpoint="0" :items-per-page="10000" hide-default-footer class="elevation-1">
                        <template #item.pregunta_evaluacion="{ item }">
                            <span>{{ item.pregunta__texto }}</span>
                        </template>
                        <template #item.incorrectas_participante="{ item }">
                            <span>{{ item.incorrectas }}</span>
                        </template>
                    </v-data-table>
                </template>
            </v-data-table>
        </div>
        <div v-if="data.tipo_reporte === 'rendimiento_curso'">
            <v-row>
                <v-col  cols="12">
                    <v-alert class="ma-0" type="success">Datos del curso</v-alert>
                    <v-data-table :headers="headers_curso" :items="curso" :mobile-breakpoint="0"  hide-default-footer>
                        <template #item.key="{ item }">
                            <span>{{ item.key }}</span>
                        </template>
                        <template #item.value="{ item }">
                            <span>{{ item.value }}</span>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
            <v-row>
                <v-col  cols="12">
                    <v-alert class="ma-0" type="success">Participantes</v-alert>
                </v-col>  
            </v-row>
            <v-row>
                <v-col  cols="12">
                    <!-- <v-data-table :headers="headers" :items="data.data.evaluaciones" :mobile-breakpoint="0" :items-per-page="10"></v-data-table> -->
                    <v-data-table :headers="headers_participantes" :items="participantes" :mobile-breakpoint="0"  :items-per-page="10">
                        <template #item.key="{ item }">
                            <span>{{ item.key }}</span>
                        </template>
                        <template #item.value="{ item }">
                            <span>{{ item.value }}</span>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
            <v-row>
                <v-col  cols="12">
                    <v-alert class="ma-0" type="success">Evaluaciones del curso</v-alert>
                </v-col>  
            </v-row>
            <v-row>
                <v-col  cols="12">
                    <v-data-table :headers="headers_cursos" :items="cursos" :mobile-breakpoint="0"  :items-per-page="5">
                        <template #item.key="{ item }">
                            <span>{{ item.key }}</span>
                        </template>
                        <template #item.value="{ item }">
                            <span>{{ item.value }}</span>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
            <v-row>
                <v-col  cols="12">
                    <v-alert class="ma-0" type="success">
                        Resúmen de preguntas y respuestas
                    </v-alert>
                </v-col>
            </v-row>
            <v-data-table :headers="headers" :items="data.data.evaluaciones" :mobile-breakpoint="0" :items-per-page="10">
                <template #item.evaluaciones_participante="{ item }">
                    <span><strong>Evaluación:</strong> {{ item.nombre }}</span><br>
                </template>
                <template #item.respuestas_participante="{ item }">
                    <v-data-table :headers="sub_headers" :items="item.preguntas" :mobile-breakpoint="0" :items-per-page="10000" hide-default-footer class="elevation-1">
                        <template #item.pregunta_evaluacion="{ item }">
                            <span>{{ item.texto }}</span>
                        </template>
                        <template #item.incorrectas_participante="{ item }">
                            <span>{{ item.cantidad_incorrectas }}</span>
                        </template>
                    </v-data-table>
                </template>
            </v-data-table>
        </div>
    </div>
</template>
<script>
import 'moment/locale/es' // Importar el idioma español
import BuscadorReporteCursosInternosRendimiento from '@/shared/components/BuscadorReporteCursosInternosRendimiento'
import { dateTime } from '@/helpers'
export default{
    props:{
        activeSubTabCursosInternos:{
            type: Number,
        }
    },
    data(){
        return{
            headers: [
                { text: 'Evaluaciones', value: 'evaluaciones_participante',sortable: false },
                { text: 'Respuestas', value: 'respuestas_participante', sortable: false }
            ],
            headers_persona: [
                { text: ' ', value: 'key',sortable: false },
                { text: ' ', value: 'value', sortable: false,align: 'right' }
            ],
            headers_curso: [
                { text: ' ', value: 'key',sortable: false },
                { text: ' ', value: 'value', sortable: false,align: 'right' }
            ],
            headers_participantes: [
                { text: 'Nombre', value: 'value', sortable: false },
                { text: 'Rut', value: 'key',sortable: false , align: 'right'}
            ],
            headers_cursos: [
                { text: 'Evaluación', value: 'key',sortable: false },
                { text: 'N° preguntas', value: 'value', sortable: false, align: 'right' },
            ],
            headers_indicadores: [
                { text: 'Parámetro', value: 'Parametro',sortable: false },
                { text: 'Porcentaje', value: 'Porcentaje', sortable: false,align: 'right' }
            ],
            sub_headers: [
                { text: 'Preguntas', value: 'pregunta_evaluacion',sortable: false },
                { text: 'Desaciertos', value: 'incorrectas_participante', sortable: false,align: 'right' }
            ],
            headers_estado: [
                { text: 'Evaluación', value: 'Evaluacion',sortable: false },
                { text: 'Curso', value: 'Curso',sortable: false },
                { text: 'Inicio', value: 'Desde',sortable: false },
                { text: 'Término', value: 'Hasta',sortable: false },
                // { text: 'Duración', value: 'Duracion',sortable: false },
                { text: 'Estado', value: 'Estado', sortable: false,align: 'right' }
            ],
            data: {},
            isData:false,
            persona:[],
            curso:[],
            participantes:[],
            cursos:[],
            indicadores:[]
        }
        
    },
    components:{
        BuscadorReporteCursosInternosRendimiento
    },
    computed: {
    },
    methods:{
        getFecha(date){
            return dateTime.formatDdMmYyHhMm(date).split(" ")[0];
        },
        getHora(date){
            return dateTime.formatDdMmYyHhMm(date).split(" ")[1] + " Hrs";
        },
        actualizarItems(nuevosItems) {
            this.data = nuevosItems;
            if(nuevosItems.tipo_reporte === 'rendimiento_persona'){
                console.log("nuevosItems para rendimiento_persona: ", nuevosItems);
                const evaluaciones = this.data.data.evaluaciones;
                const rendimiento = this.data.data.rendimiento;
                const nombreCompleto = nuevosItems.data.persona.first_name + ' ' + nuevosItems.data.persona.last_name;
                this.persona =[
                    {key:"Nombre",value:nombreCompleto},
                    {key:"Rut",value:nuevosItems.data.persona.rut},
                    {key:"Cargo",value:nuevosItems.data.persona.gerencia.nombre},
                    {key:"Empresa",value:nuevosItems.data.persona.empresa.nombre}
                ]
                this.indicadores = [
                    {key:"Evaluaciones realizadas",value:String(evaluaciones.rel_aprob.toFixed(2)) + " %"},
                    {key:"Evaluaciones no realizadas",value:String(evaluaciones.rel_inv.toFixed(2)) + " %"},
                    {key:"Respuestas correctas ",value:String(rendimiento.rel_correctas.toFixed(2)) + " %"},
                    {key:"Respuestas incorrectas ",value:String(rendimiento.rel_incorrectas.toFixed(2)) + " %"},
                ];
            } 
            if(nuevosItems.tipo_reporte === 'rendimiento_curso'){
                console.log("nuevosItems para rendimiento_curso: ", nuevosItems);
                const autor = nuevosItems.data.curso.autor + " (" + nuevosItems.data.curso.gerencia.nombre + ")";
                this.curso =[
                    {key:"Nombre curso",value:nuevosItems.data.curso.nombre},
                    {key:"Grupo de acceso",value:nuevosItems.data.grupo.nombre},
                    {key:"Autor del curso",value:autor},
                    {key:"Empresa",value:nuevosItems.data.curso.empresa.nombre},
                    {key:"N° participantes",value:nuevosItems.data.cantidad_participantes},
                    {key:"N° evaluaciones",value:nuevosItems.data.cantidad_evaluaciones}
                ]
                this.participantes = nuevosItems.data.grupo.participantes.map((participante) => {
                    return {key:participante.rut,value:participante.first_name + " " + participante.last_name}
                });
                this.cursos = nuevosItems.data.evaluaciones.map((evaluacion) => {
                    return {key:evaluacion.nombre,value:evaluacion.preguntas.length}
                });
                    
            }
            if (nuevosItems) {
                this.isData = true;
            } else {
                this.isData = false;
            }
        },
        clearTable() {
            this.data = {}; 
            this.isData = false; // hace desaparecer los botones de descarga de los pdf
        }
    }
}
</script>
<style scoped>
  .custom-background {
    background-color: #9abb7f;
  }
</style>