<template>
<div>
    <div v-if="!!error_data">
        <v-row>
            <v-col cols="12">
                <DashCard title="Error" title-icon="error_outline" class="text-center">
                    <v-icon class="d-block py-4" size="128" color="red">cancel</v-icon>
                    <v-label>
                        <template v-if="error_data.response.status == 404">La evaluación que buscas ya no existe o no está disponible.</template>
                        <template v-else-if="error_data.response.status == 401">Su sesión no tiene los permisos necesarios para acceder a este contenido. Por favor, refresque la página o vuelva a iniciar sesión.</template>
                        <template v-else-if="error_data.response.status == 500">Un error interno ocurrió en el servidor.</template>
                        <template v-else>Error desconocido</template>
                    </v-label>
                </DashCard>
            </v-col>
        </v-row>
        </div>
    <div v-else>
        <PresentationV2 class="ma-n4" v-if="app_mode == 0" ref="viewer" v-model="eval_data" @confirm="ir_cuestionario" :evaluation="true" />
        <v-row v-else-if="app_mode == 1">
            <v-col cols="12">
                <DashCard title="Examen" title-icon="play_arrow" :actions="true" actions-align="right">
                    <v-label>{{ eval_data.instruccion_exam || "Marque la alternativa correcta." }}</v-label>
                    <v-divider class="my-4" />
                    <v-row no-gutters justify="center" align="center" class="my-2">
                        <v-col class="flex-shrink-0 flex-grow-1">
                            <v-progress-linear :value="exam_progress" :height="32" color="green" rounded striped />
                        </v-col>
                        <v-col class="px-2 flex-shrink-1 flex-grow-0">
                            <v-chip label dark color="green">{{ Math.max(0, question.id - 1) }} de {{ question.total }} preguntas</v-chip>
                        </v-col>
                    </v-row>
                    <v-divider class="my-4" />
                    <PreguntaExam ref="exam" v-model="answers[question.id - 1]" :question="question.data" :number="question.id" @acierto="continuar_cuestionario" />
                    <template slot="actions">
                        <v-btn color="primary" depressed large class="subtitle-2 px-4" @click="responder" :disabled="is_loading || (this.input.answered && this.input.is_correct)" :busy="is_loading">
                            <v-icon>chevron_right</v-icon>
                            Responder
                        </v-btn>
                    </template>
                </DashCard>
            </v-col>
        </v-row>
        <v-row v-else-if="app_mode == 2">
            <v-col cols="12">
                <DashCard title="Prueba finalizada" title-icon="done" :actions="true" actions-align="right">
                    <v-row>
                        <v-col cols="12" align="center">
                            <v-icon size="128" color="green">check_circle</v-icon>
                        </v-col>
                        <v-col cols="12" align="center">
                            <span class="d-block headline text--primary">¡Ha finalizado la evaluación!</span>
                            <span class="d-block body-2">
                                ¡Felicidades!, acaba de finalizar la evaluación y sus resultados han quedado registrados.
                            </span>
                        </v-col>
                        <v-col cols="12" align="center">
                            <v-btn depressed large class="subtitle-2" color="primary" :to="{ name: 'dashboard_cap_list' }">
                                Ir a mis capacitaciones
                            </v-btn>
                        </v-col>
                    </v-row>
                </DashCard>
            </v-col>
        </v-row>
    </div>
    <Message ref="error" />
</div>
</template>

<script>
import DashCard from '@/shared/components/DashCard'
import PresentationV2 from '@/educap/components/PresentationV2'
import PreguntaExam from '@/educap/components/PreguntaExam'
import Message from '@/shared/components/Message'
import { messages } from '@/helpers'
import { mapState, mapActions } from 'vuex'

export default {
    components: {
        DashCard,
        PresentationV2,
        PreguntaExam,
        Message
    },
    data: () => ({
        title: "Evaluación",
        answers: []
    }),
    computed: {
        ...mapState('evaluacion', ['app_mode', 'is_loading', 'log_id', 'eval_data', 'error_data', 'question', 'input']),
        exam_progress() {
            return (Math.max(0, this.question.id - 1) / this.question.total) * 100;
        }
    },
    beforeRouteLeave (to, from, next) {
        if (this.ask_leave()) {
            next();
        } else {
            next(false);
        }
    },
    methods: {
        ...mapActions('evaluacion', ['validate_answer', 'trigger_success']),
        ask_leave() {
            if(this.app_mode != 1) {
                return true;
            }
            return window.confirm("¿Está seguro que desea abandonar?. Tendrá que iniciar de nuevo el examen si desea realizarlo nuevamente.");
        },
        start() {    
            var eval_id = parseInt(this.$route.params.id);
            this.$store.dispatch('evaluacion/start', { eval_id }).then(() => 
            {
                this.$refs.viewer.onResize();
            });
        },
        ir_cuestionario() {
            this.$vuetify.goTo(0);
            this.$store.dispatch('evaluacion/go_cuestionario');
        },
        responder() {
            if(!this.answers[this.question.id - 1]) {
                this.$refs.error.show(messages.titles["error_generico"], "Por favor, seleccione una respuesta.", "error");
                return;
            }

            var answer = this.answers[this.question.id - 1];
            this.validate_answer({ answer }).then(() => {
                if(this.input.answered) {
                    if(this.input.is_correct) {
                        this.$refs.exam.success();
                    } else {
                        this.$refs.exam.try_again();
                    }
                }
            });
        },
        continuar_cuestionario() {
            this.$refs.exam.reset();
            this.trigger_success();
        }
    },
    mounted() {
        this.start();
        this.$parent.$parent.set_title(this.title, "play_arrow");
    }
}
</script>