import moment from 'moment'

function redondearSegundoMasCercano(timeString){
    // Crear un objeto de duración con Moment.js
    const duration = moment.duration(timeString);
    // Redondear los milisegundos a segundos
    const roundedSeconds = Math.round(duration.asSeconds());
    // Convertir los segundos redondeados de nuevo a duración
    const roundedDuration = moment.duration(roundedSeconds, 'seconds');
    // Formatear la salida (horas:minutos:segundos)
    return `${Math.floor(roundedDuration.asHours())}:${String(roundedDuration.minutes()).padStart(2, '0')}:${String(roundedDuration.seconds()).padStart(2, '0')}`;
}

function formatDdMmYyHhMm(date){
    return moment(date).format('DD/MM/YYYY HH:mm') + ' hrs';
}

export const  dateTime = {
    redondearSegundoMasCercano,
    formatDdMmYyHhMm
};