<template>
    <div>
        <v-row>
            <v-col cols="12">
                <DashCard title="Información general" title-icon="info_outline" body-class="pa-2">
                    <v-row class="ma-0">
                        <v-col cols="12" sm="4">
                            <v-label>Nombre</v-label>
                            <span class="d-block title font-weight-regular text--primary">{{ item.nombre }}</span>
                        </v-col>
                        <v-col cols="12" sm="4" v-if="!!item.ref_incidente">
                            <v-label>Incidente</v-label>
                            <IncidenteCard v-model="item.ref_incidente" />
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                            <v-label>Evaluador</v-label>
                            <v-card outlined>
                                <v-list no-gutters class="ma-0 pa-0">
                                    <v-list-item class="py-0 px-4">
                                        <v-list-item-avatar>
                                            <v-icon x-large color="primary">account_circle</v-icon>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{ item.evaluador.first_name + ' ' + item.evaluador.last_name }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                                {{ item.evaluador.empresa.nombre }}
                                            </v-list-item-subtitle>
                                            <v-list-item-subtitle>
                                                {{ item.evaluador.gerencia.nombre }}
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-card>
                        </v-col>
                        <v-col cols="6" sm="4" lg="2">
                            <v-icon left>event</v-icon>
                            <v-label>Inicio</v-label>
                            <span class="d-block title font-weight-regular text--primary">
                                {{ item.fecha_inicio | moment('L') }}
                            </span>
                        </v-col>
                        <v-col cols="6" sm="4" lg="2">
                            <v-icon left>event</v-icon>
                            <v-label>Termino</v-label>
                            <span class="d-block title font-weight-regular text--primary">
                                {{ item.fecha_termino | moment('L') }}
                            </span>
                        </v-col>
                    </v-row>
                </DashCard>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="12">
                <DashCard :title="`Cursos (${item.cursos.length})`" title-icon="class" body-class="pa-2">
                    <v-card flat tile outlined class="overflow-y-auto" max-height="400">
                        <v-list no-gutters class="ma-0">
                            <v-list-item v-for="(curso, i) in item.cursos" :key="i">
                                <v-list-item-avatar>
                                    <v-icon large color="secondary">folder</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ curso.nombre }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        {{ !curso.empresa ? "Empresa desconocida" : curso.empresa.nombre }}, {{ !curso.gerencia ? "Gerencia desconocida" : curso.gerencia.nombre }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card>
                </DashCard>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="12">
                <DashCard :title="`Participantes (${item.participantes.length})`" title-icon="group" body-class="pa-0">
                    <v-data-table dense :headers="headers" :items="item.participantes">
                        <template v-slot:item.empresa="{ item }">
                            {{ item.empresa.nombre }}
                        </template>
                        <template v-slot:item.gerencia="{ item }">
                            {{ item.gerencia.nombre }}
                        </template>
                    </v-data-table>
                </DashCard>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import DatePickerText from '@/shared/components/DatePickerText'
import DashCard from '@/shared/components/DashCard'
import IncidenteCard from '@/educap/components/IncidenteCard'
import { axiosHelper } from '@/helpers'

export default {
    components: {
        DatePickerText,
        DashCard,
        IncidenteCard
    },
    data: () => ({
        item: null,
        headers: [
            { value: "rut", text: "R.U.T" },
            { value: "first_name", text: "Nombres" },
            { value: "last_name", text: "Apellidos" },
            { value: "empresa", text: "Empresa" },
            { value: "gerencia", text: "Gerencia" }
        ]
    }),
    mounted() {
        this.$parent.$parent.set_title("Ver grupo", "group");
        axiosHelper.get('edu/grupos/' + this.$route.params.id + '/')
            .then(response => this.item = response.data);
    }
}
</script>