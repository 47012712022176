<template>
	<v-card class="main" v-resize="onResize" tile flat dark :style="style">
		<PresentacionOverlay v-model="value" v-if="!!evaluation" @close="start_presentation" />
		<v-flex>
			<v-card flat tile color="rgba(0,0,0,0.45)" dark :style="controls_style">
				<v-flex class="ma-auto text-center">
					<v-row no-gutters>
						<v-col class="flex-shrink-1 flex-grow-0 pa-2">
                            <v-btn @click="load_content" text class="subtitle-2"><v-icon left>refresh</v-icon>Recargar</v-btn>
                        </v-col>
						<v-col class="flex-shrink-1 flex-grow-0 pa-2">
							<v-btn @click="confirm_eval" text class="subtitle-2">
								<span class="green--text font-weight-bold"><v-icon color="green" left>done</v-icon>Comenzar evaluación</span>
							</v-btn>
						</v-col>
					</v-row>
					<v-row no-gutters>
                        
                    </v-row>
				</v-flex>
			</v-card>
		</v-flex>
		<v-flex class="fill-height justify-center">
			<v-flex ref="container" class="justify-center align-center">
				 <div class="pdf-container">
					<pdf v-for="pageNum in total" :key="pageNum" :src="src" :page="pageNum" :scale.sync="scale" style="width: 100%">
						<template slot="loading">
							<v-flex text-center justify-center class="my-auto">
								<v-flex>
									<v-progress-circular class="my-4" indeterminate size="128" width="8" />
								</v-flex>
								<v-flex>
									<p class="my-4">Por favor, espere...</p>
								</v-flex>
							</v-flex>
						</template>
					</pdf>
				</div> 
               <!-- <iframe v-if="archivo" :src="proxiedArchivo" :style="style" @load="onLoad"></iframe> -->
			</v-flex>
		</v-flex>
	</v-card>
</template>
<style scoped>
.v-card.main {
    position: fixed;
    display: block;
}

.pdf-container {
    width: 100%;
    height: calc(100vh - 60px); /* Ajustar esta altura según sea necesario */
    overflow-y: auto;
}
</style>

<script>
import PresentacionOverlay from '@/educap/components/PresentacionOverlay'
import pdf from 'vue-pdf'
import DashCard from '@/shared/components/DashCard'

export default {
    props: ['value', 'evaluation'],
    components: {
        pdf,
        DashCard,
        PresentacionOverlay
    },
    data: () => ({
        window: { x: 0, y: 0 },
        size: { x: 0, y: 0 },
        page: 1,
        scale: 1,
        total: 0,
        src: null,
        loaded: false,
        controls: true
    }),
    computed: {
        archivo() {
            if (!this.value) return null;
            if (!this.value.presentacion) return null;
            if (!this.value.presentacion.archivo) return null;
            return this.value.presentacion.archivo;
        },
        style() {
            var style = "";
            style += `width: ${this.window.x}px;`;
            style += `height: ${this.window.y}px;`;
            return style;
        },
        controls_style() {
            return this.controls ? "" : "visibility: hidden";
        },
        max_size() {
            return this.size.x * (this.window.y / this.size.y);
        },
        proxiedArchivo() {
            return this.archivo ? `/pdf${this.archivo}` : null;
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.onResize();
        })
    },
    methods: {
        start_presentation() {
            this.onResize();
        },
        confirm_eval() {
            this.$emit('confirm');
        },
        load_content() {
            if (!this.archivo) return;
            this.page = 1;
            const loadingTask = pdf.createLoadingTask(this.archivo);
            if (!loadingTask) return;
            loadingTask.promise.then((pdfDoc) => {
                this.total = pdfDoc.numPages;
                this.src = this.archivo;
                this.loaded = true;
                console.log('numero de paginas:', this.total);
            }).catch((error) => {
                console.error('Error al cargar el PDF:', error);
            });
        },
        onResize() {
            this.window = {
                x: window.innerWidth - this.$vuetify.application.left, 
                y: window.innerHeight - this.$vuetify.application.top
            };
            console.log('window:', this.window);
        },
        onLoad() {
         this.loaded = true;
        }
    },
    watch: {
        archivo: function() {
            if (!this.archivo) return;
            this.load_content();
        }
    }
}
</script>

